<template>
  <v-sheet>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          :server-items-length="total"
          item-key="id"
          @pagination="pagination"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | dateTimeFormat }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="#04BF68"
              fab
              dark
              depressed
              x-small
              @click="showDetail(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogForm" persistent width="500">
      <v-card flat>
        <v-card-title class="grey">
          <div class="heading-4 font-weight-bold">
            {{ isEdit ? "Edit Category" : "Add Category" }}
          </div>
          <v-spacer />
          <v-icon class="font-weight-bold" @click="closeForm()">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <div v-if="form.icon">
                  <v-img :src="form.icon" @click="onButtonClick" />
                </div>
                <div
                  v-else
                  id="upload-image"
                  class="d-flex black--text justify-center align-center"
                  @click="onButtonClick"
                >
                  Upload Image
                </div>
                <input
                  v-show="false"
                  ref="upload"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.key"
                  :rules="[(v) => !!v || 'Category must be fill']"
                  name="schoolyear-semester"
                  label="Category"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-divider class="mb-2" />
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  :loading="loadingDialog"
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="saveCategory()"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ModalConfirm
      :dialog="dialogConfrim"
      :text="`Delete Category ${deleteData.key}?`"
      :loading="loadingDialog"
      title="Delete Category"
      @action="(r) => deleteAction(r)"
    />

    <view-image
      :viewFile="viewImage"
      :urlFile="urlFile"
      @close="viewImage = false"
    />
  </v-sheet>
</template>

<script>
import { getUser } from "@/api/admin/user";
import moment from "moment";
import ModalConfirm from "@/components/ModalConfirm.vue";
import ViewImage from "../../components/ViewImage.vue";

export default {
  components: {
    ModalConfirm,
    ViewImage,
  },
  filters: {
    dateTimeFormat: (value) => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY, HH:mm");
    },
  },
  data() {
    return {
      loading: false,
      loadingDialog: false,
      dialogConfrim: false,
      dialogForm: false,
      viewImage: false,
      search: "",
      urlFile: "",
      data: [],
      deleteData: { key: null, icon: "" },
      total: 0,
      form: {
        key: null,
        oldKey: null,
        icon: null,
        oldImage: null,
        file: null,
      },
      query: {
        page: 1,
        limit: 10,
      },
      isEdit: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Join Date",
          value: "created_at",
        },
      ];
    },
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    showDetail(item) {
      console.log(item);
    },
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getCategoryList();
    },
    closeForm(param) {
      if (param === "save") this.getCategoryList();
      this.dialogForm = false;
      this.isEdit = false;
      this.oldKey = null;
      this.$nextTick(() => {
        this.form.oldImage = null;
        this.form.file = null;
        this.form.icon = null;
        this.form.oldKey = null;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      });
    },
    showDialog(item) {
      this.dialogForm = true;
      if (item) {
        this.form.key = item.key;
        this.form.oldKey = item.key;
        this.form.icon = item.icon;
        this.isEdit = true;
      }
    },
    getCategoryList() {
      this.loading = true;
      getUser(this.query)
        .then((res) => {
          const { data } = res;
          if (data.code) {
            this.data = data.data.data;
            this.total = data.data.total;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    deleteHandler(item) {
      this.deleteData = item;
      this.dialogConfrim = true;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 1px solid gray;
  cursor: pointer;
  height: 300px;
}
</style>
